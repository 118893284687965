/** @format */

import React, { useEffect, useState } from 'react';
import { setGlobal } from 'reactn';
import { Upload, Row, Col } from 'antd';
import { Config } from '../../../../../../../_config/constants/config.constant';
import { TypeNotification } from '../../../../../../../_config/constants/notification.constant';
import CustomNotification from '../../../../../../../components/Antd/Notification';
import { serviceUploadImage } from './services';
import { Services } from '../../../services';
import './style.css';

export default function UploadImage(props) {
	const [count, setCount] = useState<any>(0);
	const [isUpload, setUpload] = useState<any>(false);
	const [fileList, setFileList] = useState<any>([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			url: props.account.image,
			image: props.account.image,
			image_thumb: props.account.image_thumb,
		},
	]);

	useEffect(() => {
		if (!props.account.image) {
			setFileList([]);
		}
		console.log('useEffect');
	}, [props]);

	const beforeUpload = (file): any => {
		console.log('beforeUpload', file);
		return new Promise((resolve, reject) => {
			const fileSize = file.size / 1024;
			if (fileSize > 100) {
				CustomNotification({
					type: TypeNotification.error,
					description: `La imagen debe pesar menos de 100kb para optimizar la carga de tu link`,
				});
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => {
				const image = new Image();
				image.src = reader.result as string;
				image.onload = () => {
					const width = image.width;
					const height = image.height;
					if (width === 300 && height === 300) {
						setUpload(true);
						resolve(file);
					} else {
						CustomNotification({
							type: TypeNotification.error,
							description: `La imagen debe tener un tamaño de 300x300 píxeles.`,
						});
						reject();
					}
				};
			};
		});
	};

	const handleOnChange = ({ fileList: item }) => {
		console.log('handleOnChange', item);

		setFileList(item);
		if (item.length > 0) {
			if (!isUpload) {
				props.componentHandle(undefined);
				setFileList([]);
				return;
			}

			if (count === 0) {
				handleSaveImage(item);
				setCount(1);
			}
		} else {
			setCount(0);
			props.componentHandle(undefined);
		}
	};

	const handleSaveImage = async (item) => {
		setGlobal({ uploadImage: true });
		console.log('handleSaveImage', item);
		console.log('name', props.account.name);
		let formData = new FormData();
		formData.append('image', item[0].originFileObj);
		formData.append('name', `${props.account.name}`);
		formData.append('key', process.env.REACT_APP_IMBB_API_KEY as string);
		try {
			const upload = await serviceUploadImage(formData);
			console.log('imagen subida', upload);
			props.account.image = upload.thumb.url;
			props.account.image_thumb = upload.thumb.url;
			if (props.componentHandle) {
				props.componentHandle(upload.thumb.url);
			}
			new Services().update(props.account);
		} catch (e) {
			console.log(e);
		}

		setGlobal({ uploadImage: false });
	};

	const getSrcFromFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		});
	};
	const handleOnPreview = async (file) => {
		const src = file.url || (await getSrcFromFile(file));
		const imgWindow = window.open(src);

		if (imgWindow) {
			const image = new Image();
			image.src = src;
			imgWindow.document.write(image.outerHTML);
		} else {
			window.location.href = src;
		}
	};

	return (
		<>
			<Row justify="center" className="own-uplodad-image">
				<Col span={7}>
					<Upload
						listType="picture-card"
						fileList={fileList}
						onChange={handleOnChange}
						onPreview={handleOnPreview}
						beforeUpload={beforeUpload}
						progress={{ strokeWidth: 2, showInfo: true }}
						accept={props.componentAccept || Config.format}
					>
						{fileList.length < 1 && '+ Imagen'}
					</Upload>
				</Col>
				<Col span={16} offset={1}>
					<br></br>
					<h5>{props.title}</h5>
					<h5>Click en el recuadro</h5>
					<h6>Dimensión 300x300</h6>
				</Col>
			</Row>
		</>
	);
}
