/** @format */

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { Icons } from '../../../../_config/constants/icons-modules.constant';
import './style.css';

export default function Modules(props) {
	const history = useHistory();
	const [styleClass] = useState(props.class);
	const [account] = useState(props.account);

	useEffect(() => {
		console.log('useEffects', 'Modules');
	}, [props]);

	return (
		<>
			<Row>
				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.biography}
						alt={'biography'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/account-biography/${account}/bio`);
						}}
					/>
					<p className={'own-modules-title'}>Biografía</p>
				</Col>
				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.links}
						alt={'links'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/account-body-links/${account}/links`);
						}}
					/>
					<p className={'own-modules-title'}>Mis Links </p>
				</Col>
				{/* <Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.color}
						alt={'color'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/account-color/${account}/color`);
						}}
					/>
					<p className={'own-modules-title'}>Colores</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.seller}
						alt={'seller'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/agent-wizzard/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>Vendedores</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.store}
						alt={'product'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/product-wizzard/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>
						Promo <br></br>Productos
					</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.service}
						alt={'service'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/service-wizzard/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>
						Promo <br></br>Servicios
					</p>
				</Col> */}

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.video}
						alt={'Video de Presentación'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/video-cover/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>Vídeo</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.phone}
						alt={'customer'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/customer-wizzard/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>Contactos</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.preview}
						alt={'preview'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/${account}`);
						}}
					/>
					<p className={'own-modules-title'}>Vista Previa</p>
				</Col>

				<Col xs={6} sm={6} xl={6} className={styleClass}>
					<img
						src={Icons.exit}
						alt={'exit'}
						className={'own-modules-img'}
						onClick={() => {
							history.push(`/profile/resume`);
						}}
					/>
					<p className={'own-modules-title'}>Salir</p>
				</Col>
			</Row>
		</>
	);
}
